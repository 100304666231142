<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold"
        >Users ({{ listMeta.total }})</v-toolbar-title
      >

      <v-spacer></v-spacer>
      <v-row v-show="showSearch" align="center" style="max-width: 300px">
        <v-text-field
          class="search"
          ref="searchField"
          flat
          hide-details
          solo
          rounded
          clearable
          background-color="grey lighten-3"
          label="Search name, email or phone number"
          v-model="filter.search"
          @input="search"
        ></v-text-field>
      </v-row>

      <v-skeleton-loader
        type="actions"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <template v-else>
        <v-select
          class="ml-10"
          hide-details
          dense
          item-text="label"
          item-value="value"
          v-model="defaultFilter"
          :items="filterBy"
          style="max-width: 200px"
          @change="filterUser"
        />

        <v-btn
          depressed
          class="ml-2"
          :color="this.status ? 'primary' : ''"
          @click="
            sortOrder = true
            filterUserBTN()
          "
        >
          <v-icon>{{ icons.arrowUp }}</v-icon>
        </v-btn>
        <v-btn
          depressed
          class="ml-2"
          :color="this.status === false ? 'primary' : ''"
          @click="
            sortOrder = false
            filterUserBTN()
          "
        >
          <v-icon>{{ icons.arrowDown }}</v-icon>
        </v-btn>

        <v-btn class="ml-5" icon @click="showSearchField">
          <img src="@/assets/icons/search.svg" alt height="17.7px" />
        </v-btn>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              color="#383d3f"
              class="mr-1"
              icon
              @click="exportList"
              v-on="on"
            >
              <v-icon>{{ icons.export }}</v-icon>
            </v-btn>
          </template>
          <span>Export to Excel</span>
        </v-tooltip>
      </template>
    </v-app-bar>
    <div
      class="mt-12 mx-4"
      id="user-list"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <v-simple-table>
        <thead>
          <tr>
            <th colspan="2" class="text-center pa-0">Name</th>
            <th class="text-left">Email</th>
            <th style="width: 100px" class="text-left">Phone number</th>
            <th style="width: 100px" class="text-left">Status</th>
            <th style="width: 50px" class="text-left">ID Verified</th>
            <th style="width: 100px" class="text-left">Added date</th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="user in users"
            :key="user.id"
            :id="user.id ? 'data-' + user.id : 'data'"
            :ref="'data-' + user.id"
            class="clickable"
            @click="
              $router.push({
                name: 'user.details',
                params: { id: user.id },
                query: {
                  sort:
                    filterData.sort === null
                      ? 'full_name'
                      : filterData.sort.toString(),
                  page:
                    filterData.page === null ? '1' : filterData.page.toString(),
                  listId: 'data-' + user.id,
                },
              })
            "
          >
            <td colspan="2">
              <user-avatar :avatar="user.avatar" size="40" class="mr-4" />
              {{ user.full_name }}
            </td>
            <td>{{ user.email || 'N/A' }}</td>
            <td>
              {{ user.phone_number ? '+' + user.phone_number : 'N/A' }}
            </td>
            <td>
              <p
                v-if="!user.blocked_at && !user.deleted_at"
                class="green--text"
              >
                Active
              </p>
              <p
                v-else-if="user.blocked_at && !user.deleted_at"
                class="red--text"
              >
                Disabled
              </p>
              <p v-else-if="user.deleted_at" class="red--text">
                Deleted Account
              </p>
            </td>
            <td class="text-center">
              <small
                class="green--text"
                v-if="
                  user.id_verification_status === IdVerificationStatus.VERIFIED
                "
                >VERIFIED</small
              >
              <!-- </v-icon> -->
              <!-- <v-icon
                color="error"
                v-else-if="
                  user.id_verification_status ===
                  IdVerificationStatus.UNVERIFIED
                "
              >
                {{ icons.close }} -->
              <!-- </v-icon> -->
              <small
                class="red--text"
                v-else-if="
                  user.id_verification_status ===
                  IdVerificationStatus.UNVERIFIED
                "
                >UNVERIFIED</small
              >
              <small
                class="warning--text"
                v-else-if="
                  user.id_verification_status ==
                  IdVerificationStatus.IN_PROGRESS
                "
                >IN PROGRESS</small
              >
              <small
                class="warning--text"
                v-else-if="
                  user.id_verification_status == IdVerificationStatus.PENDING
                "
                >PENDING</small
              >
              <small
                class="red--text"
                v-else-if="
                  user.id_verification_status ===
                  IdVerificationStatus.LOCKED_OUT
                "
                >LOCKED OUT</small
              >
              <!-- <v-icon
                color="warning"
                v-else-if="
                  user.id_verification_status ==
                  IdVerificationStatus.IN_PROGRESS
                "
              >
                {{ icons.progress }}
              </v-icon> -->
              <!-- <v-icon
                color="info"
                v-else-if="
                  user.id_verification_status === IdVerificationStatus.PENDING
                "
              >
                {{ icons.pending }}
              </v-icon> -->
              <!-- <v-icon
                color="error"
                v-else-if="
                  user.id_verification_status ===
                  IdVerificationStatus.LOCKED_OUT
                "
              >
                {{ icons.lock }}
              </v-icon> -->
              <!-- <v-icon color="error" v-else> else </v-icon> -->
            </td>
            <td class="white_space">
              <small>{{ user.addedDate }}</small>
            </td>
          </tr>

          <template v-if="loading">
            <tr v-for="index in 10" :key="index + '-skeleton'">
              <td class="py-3 pl-0" width="5%">
                <v-skeleton-loader
                  class="avatar-loading--36"
                  type="avatar"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
            </tr>
          </template>
        </tbody>
      </v-simple-table>

      <div
        class="d-flex justify-center mt-3"
        v-if="!(users.length > 0) && !loading"
      >
        <p>No match found.</p>
      </div>
    </div>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color" right>
      {{ snackbar.message }}
      <v-btn color="white" text @click="snackbar.show = false">Ok</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import {
  mdiDotsVertical,
  mdiUnfoldMoreHorizontal,
  mdiMagnify,
  mdiAccountCircleOutline,
  mdiChevronRight,
  mdiFileExcel,
  mdiArrowUp,
  mdiArrowDown,
  mdiCheckCircleOutline,
  mdiCloseCircleOutline,
  mdiLock,
  mdiLanPending,
  mdiProgressAlert,
} from '@mdi/js'
import IdVerificationStatus from '@/enums/id-verification-status'
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import UserAvatar from '@/components/UserAvatar'
import infiniteScroll from 'vue-infinite-scroll'
import debounce from 'lodash/debounce'

export default {
  name: 'UsersPage',

  mixins: [ControlsMixin],

  directives: {
    infiniteScroll,
  },

  components: {
    UserAvatar,
    AppBarNavIcon,
  },

  data() {
    return {
      IdVerificationStatus,
      loading: false,
      showSearch: false,
      showProfile: false,
      selectedProfile: null,
      filter: {
        search: '',
      },
      icons: {
        settings: mdiDotsVertical,
        sort: mdiUnfoldMoreHorizontal,
        search: mdiMagnify,
        view: mdiAccountCircleOutline,
        arrow: mdiChevronRight,
        export: mdiFileExcel,
        arrowUp: mdiArrowUp,
        arrowDown: mdiArrowDown,
        check: mdiCheckCircleOutline,
        close: mdiCloseCircleOutline,
        lock: mdiLock,
        pending: mdiLanPending,
        progress: mdiProgressAlert,
      },
      snackbar: {
        show: false,
        message: null,
        color: null,
      },
      defaultFilter:
        this.$route.query.sort === undefined
          ? 'full_name'
          : this.$route.query.sort,
      sortOrder: true,
      filterBy: [
        {
          value: 'full_name',
          label: 'Name',
        },
        {
          value: 'email',
          label: 'Email',
        },
        {
          value: 'phone_number',
          label: 'Phone number',
        },
        {
          value: 'blocked_at',
          label: 'Status',
        },
        {
          value: 'created_at',
          label: 'Added date',
        },
      ],
      filterData: {
        sort: '',
        page: '',
      },
      status: JSON.parse(sessionStorage.getItem('userToggle')),
    }
  },

  created() {
    this.clearUsers()
    this.fetchUsers(1)
    this.status =
      JSON.parse(sessionStorage.getItem('userToggle')) === null
        ? true
        : JSON.parse(sessionStorage.getItem('userToggle'))
  },

  computed: {
    ...mapState({
      users: (state) => state.user.list,
      listMeta: (state) => state.user.listMeta,
    }),

    paginationLength() {
      return (this.listMeta.total / this.listMeta.per_page) | 0
    },
  },

  methods: {
    ...mapActions({
      getUsers: 'user/getUsers',
      exportToExcel: 'user/exportToExcel',
    }),

    ...mapMutations({
      clearUsers: 'user/clearUserList',
    }),

    initUsers() {
      this.clearUsers()
      this.fetchUsers(1)
    },

    pageChanged(page) {
      this.fetchUsers(page)
    },

    // methods: {
    filterUser(filterValue) {
      this.$router.push({
        name: this.$route.name,
        query: {
          // ...this.$route.query,
          sort: this.status ? `${filterValue}` : `-${filterValue}`,
          page: '1',
        },
      })
      this.clearUsers()
      this.fetchUsers(null, filterValue)
      sessionStorage.setItem('userToggle', true)
    },

    async filterUserBTN() {
      // this.status = this.sortOrder
      sessionStorage.setItem('userToggle', this.sortOrder)
      this.status = JSON.parse(sessionStorage.getItem('userToggle'))
      this.clearUsers()
      this.loading = true
      let paramsData = {}
      if (JSON.stringify(this.$route.query) === '{}') {
        paramsData = {
          page: 1,
          // sort: this.defaultFilter,
          sort: this.status
            ? `${this.defaultFilter}`
            : `-${this.defaultFilter}`,
        }
      } else {
        paramsData = {
          page: 1,
          sort: this.status
            ? `${this.$route.query.sort}`
            : `-${this.$route.query.sort}`,
        }
      }

      this.$router
        .replace({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            sort: this.status
              ? `${this.defaultFilter}`
              : `-${this.defaultFilter}`,
            page: 1,
          },
        })
        .catch((err) => {
          if (err.name !== 'NavigationDuplicated') {
            throw err
          }
        })
      sessionStorage.setItem('userFilter', JSON.stringify(paramsData))
      await this.getUsers(paramsData)
      const data = sessionStorage.getItem('userFilter')
      this.filterData = JSON.parse(data)
      this.loading = false
    },

    loadMore() {
      if (this.listMeta.current_page < this.listMeta.last_page) {
        this.$router
          .push({
            name: this.$route.name,
            query: {
              ...this.$route.query,
              sort: this.status
                ? `${this.defaultFilter}`
                : `-${this.defaultFilter}`,
              page: this.listMeta.current_page + 1,
            },
          })
          .catch((err) => {
            if (err.name !== 'NavigationDuplicated') {
              throw err
            }
          })
        this.fetchUsers(this.listMeta.current_page + 1)
      }
    },

    search: debounce(function () {
      this.clearUsers()
      this.fetchUsers()
    }, 800),

    async scrollToUser(id) {
      this.$nextTick(() => {
        const element = this.$refs['data-' + id][0]
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' })
        }
      })
    },

    async fetchUsers(page = 1) {
      if (this.status === null) {
        this.status = true
      }
      // this.status = this.sortOrder
      let params
      if (this.loading) return
      if (JSON.stringify(this.$route.query) === '{}') {
        params = {
          page,
          sort: this.status
            ? `${this.defaultFilter}`
            : `-${this.defaultFilter}`,
        }
      } else {
        params = {
          page: parseInt(this.$route.query.page),
          sort: this.status
            ? `${this.$route.query.sort}`
            : `-${this.$route.query.sort}`,
        }
      }

      if (this.filter.search) {
        params.search = this.filter.search
      }
      this.loading = true
      sessionStorage.setItem('userFilter', JSON.stringify(params))
      await this.getUsers(params)
      this.loading = false
      const data = sessionStorage.getItem('userFilter')
      this.filterData = JSON.parse(data)
    },

    showProfileDialog(profile) {
      this.selectedProfile = profile
      this.showProfile = true
    },

    async exportList() {
      this.loading = true
      await this.exportToExcel()
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'users_list.xlsx')
          document.body.appendChild(link)
          link.click()
          this.showSnackbar('File successfully downloaded', 'success')
        })
        .catch(() => {
          this.showSnackbar('Ops! Something went wrong', 'red')
        })
      this.loading = false
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.color = color
      this.snackbar.show = true
    },

    async showSearchField() {
      this.showSearch = !this.showSearch
      await this.$nextTick()
      this.$refs.searchField.$refs.input.focus()
    },
  },

  updated() {
    if (this.$route.query.sort) {
      this.$router
        .replace({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            sort: this.$route.query.sort.toString().replace('-', ' ').trim(),
            page: parseInt(this.$route.query.page),
          },
        })
        .catch((err) => {
          if (err.name !== 'NavigationDuplicated') {
            throw err
          }
        })
      this.$route.query.sort.toString().replace('-', ' ')
    }
  },

  // mounted() {
  //   if (!sessionStorage.getItem('pageLoaded')) {
  //     sessionStorage.setItem('pageLoaded', 'true')
  //     // sessionStorage.removeItem('userToggle')
  //   }
  // },
}
</script>
<style lang="css" scoped>
/* td {
  vertical-align: super !important;
} */

@media (max-width: 600px) {
  .white_space {
    white-space: nowrap;
  }
}
</style>
